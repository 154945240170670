import SelectionArea from "../SelectionArea/SelectionArea";
import ViewArea from "../ViewArea/ViewArea";
import './Controller.css'
import React, { useState } from 'react';
export const GlobalContext = React.createContext();

function Controller() {
  const [choosenOption, set_choosenOption] = useState("Overview");
  const [choosenBuoy, set_choosenBuoy] = useState("1");

  return (
    <div className="Controller">
      <GlobalContext.Provider value={{
        choosenOption: choosenOption, set_choosenOption: set_choosenOption,
        choosenBuoy: choosenBuoy, set_choosenBuoy: set_choosenBuoy,
      }} >
        <SelectionArea />
        <ViewArea />
      </GlobalContext.Provider>
    </div>
  );
}

export default Controller;
