
function Wave() {
  return (
    <div className="Wave">
      <iframe width="100%" height="800"
        src="https://embed.windy.com/embed2.html?lat=44.676&lon=-63.603&detailLat=44.634&detailLon=-63.582&width=650&height=450&zoom=11&level=surface&overlay=waves&product=ecmwfWaves&menu=&message=true&marker=true&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=%C2%B0C&radarRange=-1"
        frameborder="0"></iframe>
    </div>
  );
}

export default Wave;
