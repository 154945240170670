
function Wave2() {
    return (
        <div className="Wave">
            <iframe width="100%" height="800" 
            src="https://embed.windy.com/embed2.html?lat=44.631&lon=-63.600&detailLat=44.631&detailLon=-63.600&width=650&height=450&zoom=11&level=surface&overlay=waves&product=ecmwfWaves&menu=&message=true&marker=true&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1" 
            frameborder="0"></iframe>
        </div>
    );
}

export default Wave2;
