import React, { useEffect } from 'react';

function LiveCamera() {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = "https://webcams.windy.com/webcams/public/embed/script/player.js";
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <h1></h1>
      {/* 1597739098 */}
      {/* <a name="windy-webcam-timelapse-player" data-id="1516544672" data-play="day" href="https://windy.com/webcams/1516544672" target="_blank">Saint John: Kennebecasis River</a> */}
      <a name="windy-webcam-timelapse-player" data-id="1597739098" data-play="day" href="https://windy.com/webcams/1597739098" target="_blank">Halifax: Museum Wharvesr</a>

    </div>
  );
}

export default LiveCamera;
