import React from 'react';
import './Water.css';

function Water() {
  const waterQualityData = [
    { time: 'Now', location: 'Bedford Basin ', temperature: 18, pH: 7.2, dissolvedOxygen: 6.8 },
    { time: '1 Hour Ago', location: 'Bedford Basin ', temperature: 17, pH: 7.5, dissolvedOxygen: 7.2 },
    { time: '2 Hours Ago', location: 'Bedford Basin ', temperature: 18, pH: 7.3, dissolvedOxygen: 6.9 },
    { time: '3 Hours Ago', location: 'Bedford Basin ', temperature: 18, pH: 7.1, dissolvedOxygen: 6.7 },
    { time: '4 Hours Ago', location: 'Bedford Basin ', temperature: 19, pH: 7.2, dissolvedOxygen: 7.1 },
    { time: '5 Hours Ago', location: 'Bedford Basin ', temperature: 19, pH: 7.0, dissolvedOxygen: 6.6 },
    { time: '6 Hours Ago', location: 'Bedford Basin ', temperature: 18, pH: 7.4, dissolvedOxygen: 6.8 },
    { time: '7 Hours Ago', location: 'Bedford Basin ', temperature: 17, pH: 7.5, dissolvedOxygen: 7.0 },
    { time: '8 Hours Ago', location: 'Bedford Basin ', temperature: 17, pH: 7.3, dissolvedOxygen: 6.9 },
    { time: '9 Hours Ago', location: 'Bedford Basin ', temperature: 18, pH: 7.2, dissolvedOxygen: 6.7 },
    { time: '10 Hours Ago', location: 'Bedford Basin ', temperature: 18, pH: 7.1, dissolvedOxygen: 6.6 },
    { time:'24 Hours Ago', location: 'Bedford Basin ', temperature: 19, pH: 7.1, dissolvedOxygen: 6.5 },
  ];

  return (
    <div>
 
      <table>
        <thead>
          <tr>
          <th>Time</th>
            <th>Location</th>
            <th>Temperature (°C)</th>
            <th>pH</th>
            <th>Dissolved Oxygen (mg/L)</th>
          </tr>
        </thead>
        <tbody>
          {waterQualityData.map((data, index) => (
            <tr key={index}>
               <td>{data.time}</td>
              <td>{data.location}</td>
              <td>{data.temperature}</td>
              <td>{data.pH}</td>
              <td>{data.dissolvedOxygen}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Water;