import React from 'react';
import "./Overview.css"
import BuoyTile from './BuoyTile/BuoyTile';

function Overview() {
  // Get these values from your actual data source
  const buoyData = [
    {
    id:  0,
    name: "George",
    wind: '10 m/s',
    waves: '2 m',
    speed: '1 m/s',
    waterQuality: 'Good',
    speedingBoats: 'None',
    daytime: 'Yes'},

    {
      id:  1,
      name: "John",
      wind: '10 m/s',
      waves: '2 m',
      speed: '1 m/s',
      waterQuality: 'Good',
      speedingBoats: 'None',
      daytime: 'Yes'},

      {
        id:  2,
        name: "Georgina",
        wind: '130 m/s',
        waves: '2 m',
        speed: '1 m/s',
        waterQuality: 'Good',
        speedingBoats: 'None',
        daytime: 'Yes'}

];

  return (
    <div className="Overview">
      <BuoyTile {...buoyData[0]} />
      <BuoyTile {...buoyData[1]} />
      <BuoyTile {...buoyData[2]} />

    </div>
  );
}

export default Overview;