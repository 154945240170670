import React from 'react';
import "./BuoyTile.css"

const BuoyTile = ({id, name, wind, waves, speed, waterQuality, speedingBoats, daytime }) => (
  <div className="buoy-tile">
    <h2>Buoy {id} ({name})</h2>
    <p><strong>Current Wind:</strong> {wind}</p>
    <p><strong>Current Wave Situation:</strong> {waves}</p>
    <p><strong>Buoy Speed:</strong> {speed}</p>
    <p><strong>Water Quality:</strong> {waterQuality}</p>
    <p><strong>Speeding Boats:</strong> {speedingBoats}</p>
    <p><strong>Daytime:</strong> {daytime}</p>
  </div>
);

export default BuoyTile;