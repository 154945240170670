import React, { useEffect, useState } from 'react';
import speedingBoat1 from './boats/speedingBoat1.jpg';
import speedingBoat2 from './boats/speedingBoat2.jpg';
import speedingBoat3 from './boats/speedingBoat3.jpg';
// import speedingBoat4 from './boats/speedingBoat4.jpg';
import './Picture.css';

function PictureStatic() {
  const pictures = [
    {
      id: 1,
      src: speedingBoat1,
      alt: 'Picture 1',
      timeStamp: '10:04am',
      speed: '12km/h (6.5 knots)',
      buoyNumber: 'Buoy 1'
    },
    {
      id: 2,
      src: speedingBoat2,
      alt: 'Picture 2',
      timeStamp: '12:47pm',
      speed: '19km/h (10.3 knots)',
      buoyNumber: 'Buoy 1'
    },
    {
      id: 3,
      src: speedingBoat3,
      alt: 'Picture 3',
      timeStamp: '2:16pm',
      speed: '15km/h (8.1 knots)',
      buoyNumber: 'Buoy 1'
    },
  ];

  return (
    <>
      <div>
        <div className="picture-container">
          {pictures.map((picture) => (
            <div className='speedingBoat-div'>
              <img
                key={picture.id}
                src={picture.src}
                alt={picture.alt}
                className="picture"

              />
              <div className='speedingBoatItems'>
                <p><span className='bold'>Speed Limit:</span> 10 km/h (5.4 knots)</p>
                <p><span className='bold'>Speed:</span> {picture.speed}</p>
                <p><span className='bold'>Time:</span> {picture.timeStamp}</p>
              </div>

            </div>
          ))}
        </div>

      </div>
    </>
  );
};

export default PictureStatic;