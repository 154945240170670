import React, { useContext } from 'react';
import { GlobalContext } from "../../../Controller/Controller";

const Header = () => {
    const localGlobal = useContext(GlobalContext);
    let title;

    switch (localGlobal.choosenOption) {
        case 'Overview':
            title = 'Overview';
            break;
        case 'LiveCamera':
            title = 'Live Camera';
            break;
        case 'Wind':
            title = 'Wind Map';
            break;
        case 'Water':
            title = 'Water Quality Data';
            break;
        case 'Wave':
            title = 'Wave Map';
            break;
        case 'AIS':
            title = 'AIS Map';
            break;
        case 'PictureStatic':
            title = 'Speeding Boats - Limit 10 km/h (5.4 knots)';
            break;
        case 'SensorStatic':
            title = 'Environmental Data';
            break;
        case 'Sensor':
            title = 'Environmental Data';
            break;
        case 'Picture':
            title = 'Picture';
            break;
        case 'PictureStatic':
            title = 'Picture';
            break;
    }

    return (
        <header style={{ backgroundColor: '#001a6c', padding: '15px', color: '#fff', width: '100%' }}>
            <h2>{title}</h2>
        </header>
    );
};

export default Header;