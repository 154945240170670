import './ViewArea.css'
import Overview from './ViewComponents/Overview/Overview';
import Wind from './ViewComponents/Wind/Wind';
import Wave from './ViewComponents/Wave/Wave';
import LiveCamera from './ViewComponents/LiveCamera/LiveCamera';
import AIS from './ViewComponents/AIS/AIS';
import Water from './ViewComponents/Water/Water';
import DownloadCSV from './ViewComponents/DonwloadCSV/DownloadCSV';
import SensorStatic from './ViewComponents/Sensor/SensorStatic';
import PictureStatic from './ViewComponents/Picture/PictureStatic';
import SensorStatic2 from './ViewComponents/Sensor/SensorStatic2'; 
import PictureStatic2 from './ViewComponents/Picture/PictureStatic2';
import Water2 from './ViewComponents/Water/Water2';
import LiveCamera2 from './ViewComponents/LiveCamera/LiveCamera2';
import Wave2 from './ViewComponents/Wave/Wave2';

import { GlobalContext } from "../Controller/Controller";
import React, { useState, useContext } from "react";
import Header from './ViewComponents/Header/Header';

function ViewArea() {
  const localGlobal = useContext(GlobalContext);
  return (
    <div className="ViewArea">
      <Header/>
      {localGlobal.choosenOption == "Overview" ? <Overview/>: <></>}
      {/* {localGlobal.choosenOption == "LiveCamera" ? <LiveCamera/>: <></>} */}
      {localGlobal.choosenOption == "Wind" ? <Wind/>: <></>}
      {/* {localGlobal.choosenOption == "Wave" ? <Wave/>: <></>} */}
      {localGlobal.choosenOption == "AIS" ? <AIS/>: <></>}
      {localGlobal.choosenOption == "DownloadCSV" ? <DownloadCSV/>: <></>}
  
      {localGlobal.choosenBuoy === "1" ? (localGlobal.choosenOption === "SensorStatic" ? <SensorStatic/> : null) : null}
      {localGlobal.choosenBuoy === "2" ? (localGlobal.choosenOption === "SensorStatic" ? <SensorStatic2/> : null) : null}

      {localGlobal.choosenBuoy === "1" ? (localGlobal.choosenOption === "PictureStatic" ? <PictureStatic/> : null) : null}
      {localGlobal.choosenBuoy === "2" ? (localGlobal.choosenOption === "PictureStatic" ? <PictureStatic2/> : null) : null}

      {localGlobal.choosenBuoy === "1" ? (localGlobal.choosenOption === "Water" ? <Water/> : null) : null}
      {localGlobal.choosenBuoy === "2" ? (localGlobal.choosenOption === "Water" ? <Water2/> : null) : null}
      
      {localGlobal.choosenBuoy === "1" ? (localGlobal.choosenOption === "LiveCamera" ? <LiveCamera/> : null) : null}
      {localGlobal.choosenBuoy === "2" ? (localGlobal.choosenOption === "LiveCamera" ? <LiveCamera2/> : null) : null}
      
      {localGlobal.choosenBuoy === "1" ? (localGlobal.choosenOption === "Wave" ? <Wave/> : null) : null}
      {localGlobal.choosenBuoy === "2" ? (localGlobal.choosenOption === "Wave" ? <Wave2/> : null) : null}
      

      {/* {localGlobal.choosenOption == "Water" ? <Water/>: <></>} */}
      {/* {localGlobal.choosenOption == "Sensor" ? <Sensor/>: <></>}
      {localGlobal.choosenOption == "Picture" ? <Picture/>: <></>} */}
      {/* {localGlobal.choosenOption == "SensorStatic" ? <SensorStatic/>: <></>} */}
      {/* {localGlobal.choosenOption == "PictureStatic" ? <PictureStatic/>: <></>} */}
    </div>
  );
  }
  
  export default ViewArea;
  