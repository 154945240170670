import "./SelectionArea.css";
import logo from "../../images/logoMiniText.png"
import { GlobalContext } from "../Controller/Controller";
import React, { useState, useContext } from "react";

function SelectionArea() 
{
  const localGlobal = useContext(GlobalContext);

  const handleOptionChange = (event) => {
    localGlobal.set_choosenBuoy(event.target.value);
  };

  return (
    <div className="SelectionArea">
      <img
        onClick={() => localGlobal.set_choosenOption("Overview")}
        src={logo}></img>
      <br />

      <div class="activeBuoy"><label for="buoys">Active Buoy: </label>

        <select name="buoys" id="buoys" value={localGlobal.choosenBuoy} onChange={handleOptionChange}>
          <option value="1" > Buoy 1 </option>
          <option value="2" > Buoy 2 </option>
        </select>
      </div>
      <button onClick={() => localGlobal.set_choosenOption("LiveCamera")}>Live Camera</button>
      <button onClick={() => localGlobal.set_choosenOption("Wave")}>Wave</button>
      <button onClick={() => localGlobal.set_choosenOption("Wind")}>Wind</button>
      <button onClick={() => localGlobal.set_choosenOption("AIS")}>AIS</button>
      <button onClick={() => localGlobal.set_choosenOption("Water")}>Water</button>
      <button onClick={() => localGlobal.set_choosenOption("SensorStatic")}>Environmental Sensors</button>
      <button onClick={() => localGlobal.set_choosenOption("PictureStatic")}>Speeding Boats</button>
    </div>
  );
}

export default SelectionArea;
