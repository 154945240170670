import React from 'react';
import "./DownloadCSV.css";

function DownloadCSV() {
    const handleDownload = async () => {
        try {
            const response = await fetch('http://ec2-3-99-218-208.ca-central-1.compute.amazonaws.com/download');
            const blob = await response.blob();
            //creates a temporary download URL
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'data.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return <button class ="download-btn" onClick={handleDownload}>Download</button>;
}

export default DownloadCSV;