import React from 'react';
import DownloadCSV from '../DonwloadCSV/DownloadCSV';

function SensorStatic() {

    const oceanographicData = [

        {
            "accel_only": {
                "pitch": 239.9073262253243,
                "roll": 3.103260781288113,
                "yaw": 99.1290743863653
            },
            "gyro_only": {
                "pitch": 423.46436023686664,
                "roll": 6.233809229237818,
                "yaw": 239.93929978260792
            },
            "humidity": 15.176464080810547,
            "north": 290.91930098871812,
            "orientation": {
                "pitch": 215.5863570203966,
                "roll": 3.281203884820792,
                "yaw": 105.12333080032843
            },
            "pressure": 1000,
            "temp": 33.24474411010742
        }
    ];

    return (
        <div>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Accel Only Pitch</th>
                            <th>Accel Only Roll</th>
                            <th>Accel Only Yaw</th>
                        </tr>
                    </thead>

                    <tbody>
                        {oceanographicData.map(data => (
                            <tr>
                                <td>{data.accel_only.pitch.toFixed(2)}</td>
                                <td>{data.accel_only.roll.toFixed(2)}</td>
                                <td>{data.accel_only.yaw.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <table>
                    <thead>
                        <tr>
                            <th>Gyro Only Pitch</th>
                            <th>Gyro Only Roll</th>
                            <th>Gyro Only Yaw</th>
                        </tr>
                    </thead>

                    <tbody>
                        {oceanographicData.map(data => (
                            <tr>
                                <td>{data.gyro_only.pitch.toFixed(2)}</td>
                                <td>{data.gyro_only.roll.toFixed(2)}</td>
                                <td>{data.gyro_only.yaw.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <table>
                    <thead>
                        <tr>
                            <th>Orientation Pitch</th>
                            <th>Orientation Roll</th>
                            <th>Orientation Yaw</th>
                        </tr>
                    </thead>

                    <tbody>
                        {oceanographicData.map(data => (
                            <tr>
                                <td>{data.orientation.pitch.toFixed(2)}</td>
                                <td>{data.orientation.roll.toFixed(2)}</td>
                                <td>{data.orientation.yaw.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>


                <table>
                    <thead>
                        <tr>
                            <th>Humidity</th>
                            <th>North</th>
                            <th>Pressure</th>
                            <th>Temperature</th>
                        </tr>
                    </thead>

                    <tbody>
                        {oceanographicData.map(data => (
                            <tr>
                                <td>{data.humidity.toFixed(2)}</td>
                                <td>{data.north.toFixed(2)}</td>
                                <td>{data.pressure.toFixed(2)}</td>
                                <td>{data.temp.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* <DownloadCSV /> */}
        </div>

    );

}

export default SensorStatic;
