
function AIS() {
  return (
    <div className="AIS">
      <iframe name="marinetraffic" id="marinetraffic" scrolling="yes"
        src="https://www.marinetraffic.com/en/ais/embed/zoom:12/centery:44.6510/centerx:-63.56/maptype:1/shownames:false/mmsi:0/shipid:0/fleet:/fleet_id:/vtypes:/showmenu:false/remember:false" width="100%" height="800" frameBorder="0">Browser does not support embedded objects.<br />Visit directly <a href="http://www.marinetraffic.com/">www.marinetraffic.com</a></iframe>
    </div>
  );
}

export default AIS;
